import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { PrimaryButton, FieldTextInput } from '../../../components';


import css from './SignupForm.module.css';

const BuyerFields = props => {

  const {
    intl,
    formId,
    termsAndConditions,
    inProgress,
    invalid,
    rootClassName,
    className
  } = props

  // email
  const emailRequired = validators.required(
    intl.formatMessage({
      id: 'SignupForm.emailRequired',
    })
  );
  const emailValid = validators.emailFormatValid(
    intl.formatMessage({
      id: 'SignupForm.emailInvalid',
    })
  );

  // password
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(
    passwordMinLengthMessage,
    validators.PASSWORD_MIN_LENGTH
  );
  const passwordMaxLength = validators.maxLength(
    passwordMaxLengthMessage,
    validators.PASSWORD_MAX_LENGTH
  );
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = validators.composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  const classes = classNames(rootClassName || css.root, className);
  const submitInProgress = inProgress;
  const submitDisabled = invalid || submitInProgress;

  return (
    <>
      <div>
        <FieldTextInput
          className={css.field}
          type="email"
          id={formId ? `${formId}.email` : 'email'}
          name="email"
          autoComplete="email"
          label={intl.formatMessage({
            id: 'SignupForm.emailLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.emailPlaceholder',
          })}
          validate={validators.composeValidators(emailRequired, emailValid)}
        />
        <div className={css.name}>
          <FieldTextInput
            className={css.firstNameRoot}
            type="text"
            id={formId ? `${formId}.fname` : 'fname'}
            name="fname"
            autoComplete="given-name"
            label={intl.formatMessage({
              id: 'SignupForm.firstNameLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.firstNamePlaceholder',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'SignupForm.firstNameRequired',
              })
            )}
          />
          <FieldTextInput
            className={css.lastNameRoot}
            type="text"
            id={formId ? `${formId}.lname` : 'lname'}
            name="lname"
            autoComplete="family-name"
            label={intl.formatMessage({
              id: 'SignupForm.lastNameLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.lastNamePlaceholder',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'SignupForm.lastNameRequired',
              })
            )}
          />
        </div>
        <FieldTextInput
          className={css.password}
          type="password"
          id={formId ? `${formId}.password` : 'password'}
          name="password"
          autoComplete="new-password"
          label={intl.formatMessage({
            id: 'SignupForm.passwordLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.passwordPlaceholder',
          })}
          validate={passwordValidators}
        />
      </div>

      <div className={css.bottomWrapper}>
        {termsAndConditions}
        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
          <FormattedMessage id="SignupForm.signUp" />
        </PrimaryButton>
      </div>
    </>
  );
};

export default BuyerFields;
