import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldSelect } from '../../../components';
import { userRoles, SELLER_ROLE } from '../../../util/types';

import BuyerFields from './BuyerFields';
import SellerFields from './SellerFields';

import css from './SignupForm.module.css';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        values,
        form
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const [sellerFormCurrentStep, setSellerFormCurrentStep] = useState(1)

      const goToNextStep = () => {
        setSellerFormCurrentStep(2);
        typeof window !== 'undefined' && window.scrollTo(0, 0);
      }
      const goToPrevStep = () => {
        setSellerFormCurrentStep(1);
      }

      const clearFormValues = () => {
        // Seller fields from step 1
        form.change("storeName", null)
        form.change("phoneNumber", null)
        form.change("websiteURL", null)
        form.change("country", null)
        form.change("wholesaleProductsNumber", null)
        form.change("workWithStoresNumber", null)
        form.change("primaryCategory", null)
        form.change("howHearAboutUs", null)
        // Seller fields from step 2
        form.change("recipientFullName", null)
        form.change("recipientPhoneNumber", null)
        form.change("recipientAddress", null)
        form.change("recipientBankName", null)
        form.change("recipientCheckingAccountNumber", null)
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          {sellerFormCurrentStep !== 2 && (
            <FieldSelect
              id="userRole"
              className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.userRole })}
              name="userRole"
              label={intl.formatMessage({ id: 'SignupForm.userRoleLabel' })}
              validate={validators.required(
                intl.formatMessage({
                  id: 'SignupForm.userRoleRequired',
                })
              )}
              onChange={() => clearFormValues()}
            >
              <option disabled value="">
                {intl.formatMessage({ id: 'SignupForm.userRolePlaceholder' })}
              </option>
              {userRoles.map(role => (
                <option key={role.key} value={role.key}>
                  {role.label}
                </option>
              ))}
            </FieldSelect>
          )}


          {values?.userRole === SELLER_ROLE?.key ? (
            <SellerFields
              intl={intl}
              formId={formId}
              termsAndConditions={termsAndConditions}
              inProgress={inProgress}
              invalid={invalid}
              rootClassName={rootClassName}
              className={className}
              values={values}
              sellerFormCurrentStep={sellerFormCurrentStep}
              goToNextStep={goToNextStep}
              goToPrevStep={goToPrevStep}
            />
          ) : (
            <BuyerFields
              intl={intl}
              formId={formId}
              termsAndConditions={termsAndConditions}
              inProgress={inProgress}
              invalid={invalid}
              rootClassName={rootClassName}
              className={className}
            />
          )}
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
