import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { 
  Button, 
  PrimaryButton, 
  FieldTextInput, 
  FieldSelect, 
  IconCustomArrow,
  FieldLocationAutocompleteInput
} from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';

import css from './SignupForm.module.css';

const identity = v => v;

const SellerFields = props => {

  const {
    intl,
    formId,
    termsAndConditions,
    inProgress,
    invalid,
    rootClassName,
    className,
    values,
    sellerFormCurrentStep,
    goToNextStep,
    goToPrevStep
  } = props

  // email
  const emailRequired = validators.required(
    intl.formatMessage({
      id: 'SignupForm.emailRequired',
    })
  );
  const emailValid = validators.emailFormatValid(
    intl.formatMessage({
      id: 'SignupForm.emailInvalid',
    })
  );

  // password
  const passwordRequiredMessage = intl.formatMessage({
    id: 'SignupForm.passwordRequired',
  });
  const passwordMinLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooShort',
    },
    {
      minLength: validators.PASSWORD_MIN_LENGTH,
    }
  );
  const passwordMaxLengthMessage = intl.formatMessage(
    {
      id: 'SignupForm.passwordTooLong',
    },
    {
      maxLength: validators.PASSWORD_MAX_LENGTH,
    }
  );
  const passwordMinLength = validators.minLength(
    passwordMinLengthMessage,
    validators.PASSWORD_MIN_LENGTH
  );
  const passwordMaxLength = validators.maxLength(
    passwordMaxLengthMessage,
    validators.PASSWORD_MAX_LENGTH
  );
  const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
  const passwordValidators = validators.composeValidators(
    passwordRequired,
    passwordMinLength,
    passwordMaxLength
  );

  const classes = classNames(rootClassName || css.root, className);
  const submitInProgress = inProgress;
  const submitDisabled = invalid || submitInProgress;

  const config = useConfiguration();

  console.log(config);

  const {
    countryOptions,
    wholesaleProductsNumbers,
    workWithStoresNumbers,
    primaryCategories,
    howHearAboutUsOptions,
  } = config;


  return sellerFormCurrentStep === 2 ? (
    <>
      <div className={css.goBackBtn} onClick={() => goToPrevStep()}>
        <IconCustomArrow direction="left" />
        <FormattedMessage id="SignupForm.goBackBtn" />
      </div>

      <h2 className={css.stepTitle}>
        <FormattedMessage id="SignupForm.step2Title" />
      </h2>
      <p className={css.stepSubTitle}>
        <FormattedMessage id="SignupForm.step2SubTitle" />
      </p>

      <FieldTextInput
        className={css.field}
        type="text"
        id={formId ? `${formId}.recipientFullName` : 'recipientFullName'}
        name="recipientFullName"
        label={intl.formatMessage({
          id: 'SignupForm.recipientFullNameLabel',
        })}
        placeholder={intl.formatMessage({
          id: 'SignupForm.recipientFullNamePlaceholder',
        })}
        validate={validators.required(
          intl.formatMessage({
            id: 'SignupForm.recipientFullNameRequired',
          })
        )}
      />

      <FieldTextInput
        className={css.field}
        type="text"
        id={formId ? `${formId}.recipientPhoneNumber` : 'recipientPhoneNumber'}
        name="recipientPhoneNumber"
        label={intl.formatMessage({
          id: 'SignupForm.recipientPhoneNumberLabel',
        })}
        placeholder={intl.formatMessage({
          id: 'SignupForm.recipientPhoneNumberPlaceholder',
        })}
        validate={validators.required(
          intl.formatMessage({
            id: 'SignupForm.recipientPhoneNumberRequired',
          })
        )}
      />

      <FieldLocationAutocompleteInput
        rootClassName={css.field}
        inputClassName={css.locationAutocompleteInput}
        iconClassName={css.locationAutocompleteInputIcon}
        predictionsClassName={css.predictionsRoot}
        validClassName={css.validLocation}
        id={formId ? `${formId}.recipientAddress` : 'recipientAddress'}
        name="recipientAddress"
        label={intl.formatMessage({
          id: 'SignupForm.recipientAddressLabel',
        })}
        placeholder={intl.formatMessage({
          id: 'SignupForm.recipientAddressPlaceholder',
        })}
        useDefaultPredictions={false}
        format={identity}
        valueFromForm={values.recipientAddress}
        css={css}
        validate={validators.required(
          intl.formatMessage({
            id: 'SignupForm.recipientAddressRequired',
          })
        )}
      />

      <FieldTextInput
        className={css.field}
        type="text"
        id={formId ? `${formId}.recipientBankName` : 'recipientBankName'}
        name="recipientBankName"
        label={intl.formatMessage({
          id: 'SignupForm.recipientBankNameLabel',
        })}
        placeholder={intl.formatMessage({
          id: 'SignupForm.recipientBankNamePlaceholder',
        })}
        validate={validators.required(
          intl.formatMessage({
            id: 'SignupForm.recipientBankNameRequired',
          })
        )}
      />

      <FieldTextInput
        className={css.field}
        type="text"
        id={formId ? `${formId}.recipientCheckingAccountNumber` : 'recipientCheckingAccountNumber'}
        name="recipientCheckingAccountNumber"
        label={intl.formatMessage({
          id: 'SignupForm.recipientCheckingAccountNumberLabel',
        })}
        placeholder={intl.formatMessage({
          id: 'SignupForm.recipientCheckingAccountNumberPlaceholder',
        })}
        validate={validators.required(
          intl.formatMessage({
            id: 'SignupForm.recipientCheckingAccountNumberRequired',
          })
        )}
      />

      <div className={css.bottomWrapper}>
        {termsAndConditions}
        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
          <FormattedMessage id="SignupForm.signUp" />
        </PrimaryButton>
      </div>
    </>
  ) : (
    <>
      <h2 className={css.stepTitle}>
        <FormattedMessage id="SignupForm.step1Title" />
      </h2>

      <div>
        <div className={css.name}>
          <FieldTextInput
            className={css.firstNameRoot}
            type="text"
            id={formId ? `${formId}.fname` : 'fname'}
            name="fname"
            autoComplete="given-name"
            label={intl.formatMessage({
              id: 'SignupForm.firstNameLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.firstNamePlaceholder',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'SignupForm.firstNameRequired',
              })
            )}
          />
          <FieldTextInput
            className={css.lastNameRoot}
            type="text"
            id={formId ? `${formId}.lname` : 'lname'}
            name="lname"
            autoComplete="family-name"
            label={intl.formatMessage({
              id: 'SignupForm.lastNameLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.lastNamePlaceholder',
            })}
            validate={validators.required(
              intl.formatMessage({
                id: 'SignupForm.lastNameRequired',
              })
            )}
          />
        </div>

        <FieldTextInput
          className={css.field}
          type="text"
          id={formId ? `${formId}.storeName` : 'storeName'}
          name="storeName"
          label={intl.formatMessage({
            id: 'SignupForm.shopNameLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.shopNamePlaceholder',
          })}
          validate={validators.required(
            intl.formatMessage({
              id: 'SignupForm.shopNameRequired',
            })
          )}
        />

        <FieldTextInput
          className={css.field}
          type="email"
          id={formId ? `${formId}.email` : 'email'}
          name="email"
          autoComplete="email"
          label={intl.formatMessage({
            id: 'SignupForm.emailLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.emailPlaceholder',
          })}
          validate={validators.composeValidators(emailRequired, emailValid)}
        />

        <FieldTextInput
          className={css.field}
          type="text"
          id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
          name="phoneNumber"
          label={intl.formatMessage({
            id: 'SignupForm.phoneNumberLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.phoneNumberPlaceholder',
          })}
          validate={validators.required(
            intl.formatMessage({
              id: 'SignupForm.phoneNumberRequired',
            })
          )}
        />

        <FieldTextInput
          className={css.field}
          type="text"
          id={formId ? `${formId}.websiteURL` : 'websiteURL'}
          name="websiteURL"
          label={intl.formatMessage({
            id: 'SignupForm.websiteURLLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.websiteURLPlaceholder',
          })}
        // validate={validators.required(
        //   intl.formatMessage({
        //     id: 'SignupForm.websiteURLRequired',
        //   })
        // )}
        />

        <FieldSelect
          id={formId ? `${formId}.country` : 'country'}
          className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.country })}
          name="country"
          label={intl.formatMessage({ id: 'SignupForm.countryLabel' })}
          validate={validators.required(
            intl.formatMessage({
              id: 'SignupForm.countryRequired',
            })
          )}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'SignupForm.countryPlaceholder' })}
          </option>
          {countryOptions.map(country => (
            <option key={country.key} value={country.key}>
              {country.label}
            </option>
          ))}
        </FieldSelect>

        <FieldSelect
          id={formId ? `${formId}.wholesaleProductsNumber` : 'wholesaleProductsNumber'}
          className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.wholesaleProductsNumber })}
          name="wholesaleProductsNumber"
          label={intl.formatMessage({ id: 'SignupForm.wholesaleProductsNumberLabel' })}
          validate={validators.required(
            intl.formatMessage({
              id: 'SignupForm.wholesaleProductsNumberRequired',
            })
          )}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'SignupForm.wholesaleProductsNumberPlaceholder' })}
          </option>
          {wholesaleProductsNumbers.map(number => (
            <option key={number.key} value={number.key}>
              {number.label}
            </option>
          ))}
        </FieldSelect>

        <FieldSelect
          id={formId ? `${formId}.workWithStoresNumber` : 'workWithStoresNumber'}
          className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.workWithStoresNumber })}
          name="workWithStoresNumber"
          label={intl.formatMessage({ id: 'SignupForm.workWithStoresNumberLabel' })}
          validate={validators.required(
            intl.formatMessage({
              id: 'SignupForm.workWithStoresNumberRequired',
            })
          )}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'SignupForm.workWithStoresNumberPlaceholder' })}
          </option>
          {workWithStoresNumbers.map(number => (
            <option key={number.key} value={number.key}>
              {number.label}
            </option>
          ))}
        </FieldSelect>

        <FieldSelect
          id={formId ? `${formId}.primaryCategory` : 'primaryCategory'}
          className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.primaryCategory })}
          name="primaryCategory"
          label={intl.formatMessage({ id: 'SignupForm.primaryCategoryLabel' })}
          validate={validators.required(
            intl.formatMessage({
              id: 'SignupForm.primaryCategoryRequired',
            })
          )}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'SignupForm.primaryCategoryPlaceholder' })}
          </option>
          {primaryCategories.map(number => (
            <option key={number.key} value={number.key}>
              {number.label}
            </option>
          ))}
        </FieldSelect>


        <FieldSelect
          id={formId ? `${formId}.howHearAboutUs` : 'howHearAboutUs'}
          className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.howHearAboutUs })}
          name="howHearAboutUs"
          label={intl.formatMessage({ id: 'SignupForm.howHearAboutUsLabel' })}
          // validate={validators.required(
          //   intl.formatMessage({
          //     id: 'SignupForm.primaryCategoryRequired',
          //   })
          // )}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'SignupForm.howHearAboutUsPlaceholder' })}
          </option>
          {howHearAboutUsOptions.map(number => (
            <option key={number.key} value={number.key}>
              {number.label}
            </option>
          ))}
        </FieldSelect>



        <FieldTextInput
          className={css.password}
          type="password"
          id={formId ? `${formId}.password` : 'password'}
          name="password"
          autoComplete="new-password"
          label={intl.formatMessage({
            id: 'SignupForm.passwordLabel',
          })}
          placeholder={intl.formatMessage({
            id: 'SignupForm.passwordPlaceholder',
          })}
          validate={passwordValidators}
        />
      </div>

      <div className={css.bottomWrapper}>
        <Button type="button" disabled={submitDisabled} onClick={() => goToNextStep()}>
          {/* <Button type="button" disabled={false} onClick={() => goToNextStep()}> */}
          <FormattedMessage id="SignupForm.next" />
        </Button>
      </div>
    </>
  )
};

export default SellerFields;
